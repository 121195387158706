import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Golden Lion Group Customer Story - Rotaready"
          description="Since getting started with Rotaready, Golden Lion Group have been able to better deploy 16 hours per week. Find out how."
          url="customer-stories/hospitality-golden-lion-group"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Picturesque view of a pub overlooking a lake"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Golden Lion Group logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since getting started with Rotaready, Golden Lion Group have been able to better deploy 16 hours per week. Find out how." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="7" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="88" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="577%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="16 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Golden Lion Group" />
              </Content>

              <Content>
                <TextBlock text="Established in the early 1980's, family-run pub company Golden Lion Group began life as The Longman of Wilmington. It has since grown to be a group of 7 pubs based across Brighton and Hove." />
              </Content>

              <Content>
                <TextBlock text="The group prides itself on delivering high standards of service across the business, and something that influences this heavily is having the right number of staff with the right skillset working at the right times." />
              </Content>

              <Content>
                <TextBlock text="When Golden Lion Group first started out they were using spreadsheets to schedule staff time. But this proved ineffective at ensuring the perfect level of staff were on hand to meet demand; especially as the business began to grow. Rotas were being copied from previous weeks and forecasting sales was done based purely on intuition, rather than past data or upcoming trends." />
              </Content>

              <Content>
                <TextBlock text="The group wanted to move on from this laborious approach and be able to build more informed staff schedules. At the same time they wanted to give employees a way to easily view their upcoming shifts and generally give the business greater control and visibility over staff rotas. So they set about finding a cloud-based solution to help them build more accurate rotas and in less time, without over complicating the scheduling process for managers." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Selecting the perfect partner" />
              </Content>

              <Content>
                <TextBlock text="After considering a number of suppliers Golden Lion Group decided to partner with Rotaready." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready is the perfect balance between intelligence and simplicity. This combined with the transparency they provide over their development pipeline gave us great confidence it was a scalable solution that would be able to grow with us. They were the obvious choice when it came to selecting our workforce management partner."
                  attribution="Leo Day - Managing Director"
                />
              </Content>

              <Content>
                <TextBlock text="Wanting to reap the benefits of Rotaready as quickly as possible, the group chose an internal champion to work alongside Rotaready's customer success team. The result was a seamless rollout with all 7 sites up and running in one week." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Chocolate dessert"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Above and beyond expectations" />
              </Content>

              <Content>
                <TextBlock text="Golden Lion Group's initial ambitions were to build more informed staff schedules and give employees a way of viewing their upcoming shifts, but it soon became apparent that Rotaready could deliver on far more." />
              </Content>

              <Content>
                <TextBlock text="Managers were saving a huge amount of time when building and sharing staff rotas and tracking attendance, employees embraced the Rotaready app to view and manage their upcoming shifts from wherever they were, and head office were benefiting greatly from increased visibility over the availability of General Managers and Rotaready's advanced cost control tools. The benefits that came with Rotaready were above and beyond what the group had expected." />
              </Content>

              <Content>
                <TextBlock text="Since implementing Rotaready the business has seen a vast improvement in its labour percentage thanks to accurate attendance monitoring. Staff retention has also improved, and crucially wage overspend is under control thanks to accurate sales forecasts. The combination of all of these factors is saving the business over £350 every week." />
              </Content>

              <Content>
                <Blockquote
                  text="Not only has Rotaready has helped us save a significant amount on unnecessary wage spend, we've been able to better deploy over 16 hours per week across the business through smarter processes and also boost our revenue through better visibility of forecasts. As a result we've seen a 577% return on our investment."
                  attribution="Leo Day - Managing Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Integrating systems across the business" />
              </Content>

              <Content>
                <TextBlock>
                  Rotaready are continuing to work closely with Golden Lion Group as they grow. Next on the agenda is enabling some of Rotaready's free <Link style="color:inherit;" to="/integrations">integrations</Link>. Golden Lion Group use Flow as its training system and ICRTouch for EPOS, both of whom Rotaready have already built real-time <Link style="color:inherit;" to="/integrations">integrations</Link> with.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="Switching on Flow will mean employee accounts in Rotaready will automatically sync to Flow, removing the need to manually update new joiners and leavers in both systems. For ICRTouch, hourly sales data will automatically feed into Rotaready for every venue and revenue stream, and be immediately available across all of Rotaready's cost and labour control tools." />
              </Content>
            </Section>

            <Section>
              <PopQuote text="There's no doubt this joined-up approach will help Golden Lion Group take its workforce management to the next level." />
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Sausages and mashed potatoes"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_goldenliongroup"
                label="rotaready.com/customer-stories/hospitality-golden-lion-group"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Golden-Lion-Group.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-glg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-glg.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-glg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/inline-glg2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
